import './App.scss';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Actms from 'pages/Actms';
import FieldForce from 'pages/FieldForce';
import Roving from 'pages/Roving';
import Toft from 'pages/Toft';

const App: React.FC = () => (
  <HelmetProvider context={{}}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/actms-privacy-policy"
          element={<Actms />}
        />
        <Route
          path="/toft-privacy-policy"
          element={<Toft />}
        />
        <Route
          path="/roving-privacy-policy"
          element={<Roving />}
        />
        <Route
          path="/field-forces-privacy-policy"
          element={<FieldForce />}
        />
        <Route
          key="router-notfound"
          path="*"
          element={(
            <div className="notfound">
              <h1>
                Not found!
              </h1>
            </div>
          )}
        />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
);

export default App;
